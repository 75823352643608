.app__blog {
    display: flex;
    flex-direction: column; 
	margin-bottom: 10px;   
}

.app__blog-all {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	justify-content: center;
	margin: 4rem 0 0 0 ;
	a{
		text-decoration: none;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		background-color: var(--secondary-color);
		color: #fff;
		font-weight: 800;
		cursor: pointer;
		
		&:hover{
			transform: scale(0.98);
		}

		@media screen and (min-width: 2000px) {
			padding: 1rem 2rem;
			border-radius: 0.85rem;
		}
	}
}

.app__blog-container {
    display: flex;
    flex-direction: row;
	margin: 4rem 0;
	@media screen and (max-width: 990px) {
		flex-direction: column-reverse;
	}
	.app__blog-container_groupA {
		flex: 0.75;
		margin-right: 2rem;
		@media screen and (max-width: 990px) {
			margin: 2rem 0;
			width: 48%;
			height: 250px;
		}
		@media screen and (max-width: 700px) {
			width: 100%;
		}
	}
	
	.app__blog-container_groupB {
		flex: 1;
	
		display:grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2rem;
	
		@media screen and (max-width: 700px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	
	@media screen and (max-width:300px) {
		width: 100%;
		margin: 1rem;
	}
}

.app__blog-container_article {
    width: 100%;
    height: 100%;
	
	border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
	color: #000;
	cursor: pointer;
	transition: all 0.3s ease;
	&:hover{
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
	}
	.app__blog-container_article-image {

		width: 100%;
		background: var(--color-bg);
		
		img {
			border-top-right-radius: 0.5rem;
			border-top-left-radius: 0.5rem;
			width: 100%;
			height: 100%;
		}
		@media screen and (max-width: 990px) {
			height: 250px;
		}
	}
	.app__blog-container_article-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	
		padding: 1rem 1.5rem;
		height: 100%;
	
		p {
			font-family: var(--font-family);
			font-size: 11.65px;
			font-weight: 700;
			line-height: 35px;
			color: #000;
		}
	
		h3 {
			font-family: var(--font-family);
			font-size: 25.11px;
			font-weight: 800;
			line-height: 30.30px;
			
			color: #000;
			margin-bottom: 2.5rem;
			cursor: pointer;
	
			@media screen and (max-width: 550px) {
				font-size: 18px;
				line-height: 25p;
			}

			
		}
		a{
			text-decoration: none;
			color: var(--secondary-color);
		}
	
		p:last-child {
			cursor: pointer;
		}
	}

	@media screen and (max-width: 990px) {
		width: 48%;
	}
	
	@media screen and (max-width: 700px) {
		width: 100%;
	}
}




